.productDescription {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
}
.productDetails-image {
  // height: calc(100vh - 7rem);
  min-height: 40vw;
  height: 40vw;
  width: 100%;
}

.productDetails-imageContainer {
  // margin-top: 3rem;
  // width: 40%;
  width: 40vw;
}

.productDetails-imageCarousel {
  width: 100%;
}
.productDetails-Content {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  width: 50%;
}
.productDetails-SizeContainer {
  width: 100%;
  span {
    font-size: 1.3rem;
  }
  .productDetails-SizeBtnContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 16px;
    padding: 10px 0;
    .productDetails-SizeBtnSelected {
      background-color: var(--navBarBG) !important;
      color: white;
    }
    .productDetails-SizeBtn {
      min-width: 50px;
      height: 30px;
      border: 2px solid var(--navBarBG);
      background-color: transparent;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
.productDetails-StylesContainer {
  width: 100%;
  span {
    font-size: 1.3rem;
  }
  .productDetails-StyleBtnContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 16px;
    padding: 10px 0;
    .productDetails-StyleBtnSelected {
      background-color: var(--navBarBG) !important;
      color: white;
    }
    .productDetails-StyleBtn {
      min-width: 50px;
      height: 30px;
      border: 2px solid var(--navBarBG);
      background-color: transparent;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
.selectQuantity-Container {
  span {
    font-size: 1.3rem;
  }
  .selectQuantity-Box {
    display: flex;
    width: 150px;
    height: 40px;
    margin-top: 10px;
    .selectQuantity-AddBtn {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      color: white;
      background-color: var(--navBarBG);
      cursor: pointer;
      user-select: none;
    }
    .selectQuantity-SubtractBtn {
      cursor: pointer;
      user-select: none;
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      color: white;
      background-color: var(--navBarBG);
    }
    .selectQuantity-text {
      cursor: default;
      user-select: none;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--navBarBG);
      font-size: 1.3rem;
      //   background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
.productDetails-btnsContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  .productDetails-addToCart {
    width: 200px;
    height: 50px;
    font-size: 1.2rem;
    background-color: transparent;
    border: 2px solid var(--navBarBG);
    color: var(--navBarBG);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: var(--navBarBG);
      border: 2px solid var(--navBarBG);
      color: white;
    }
  }
  .productDetails-buyNow {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    font-size: 1.2rem;
    background-color: transparent;
    border: 2px solid var(--navBarBG);
    color: var(--navBarBG);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: var(--navBarBG);
      border: 2px solid var(--navBarBG);
      color: white;
    }
  }
}
@include media(max-width, 780px) {
  .productDetails-imageContainer {
    // width: 45%;
    // height: 45%;
    // min-height: 45%;
  }
  .productDetails-Content {
    width: 50%;
  }
}
@include media(max-width, 600px) {
  .productDetails-addToCart {
    width: 150px !important;
    height: 40px !important;
    font-size: 1rem !important;
    margin-top: -10px !important;
  }
  .productDetails-buyNow {
    width: 150px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }
  .selectQuantity-Container {
    .selectQuantity-Box {
      display: flex;
      width: 140px;
      height: 30px;
      margin-top: 10px;
      .selectQuantity-AddBtn {
        font-size: 1.2rem;
      }
      .selectQuantity-SubtractBtn {
        font-size: 1.2rem;
      }
      .selectQuantity-text {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .productDetails-imageContainer {
    // width: 45%;
    // height: 45%;
    // min-height: 45%;
  }
  .productDetails-Content {
    width: 50%;
  }
}
@include media(max-width, 525px) {
#carouselExampleIndicators{
	height: auto !important;
}  
.productDetails-image {
    height: 100%;
    width: 100vw;
    min-height: 100vw;
  }

  .productDetails-imageContainer {
    margin-top: 3rem;
    width: 100vw;
    height: 100% !important;
    min-height: 100vw;
  }

  .productDetails-imageCarousel {
    width: 100%;
    height: 100vh;
    margin-bottom: 2rem;
  }
  .productDetails-Container {
    display: flex;
    flex-direction: column !important;
    min-height: auto !important;
    height: auto !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
  .productDetails-Content {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  .productDetails-SizeContainer {
    width: 100%;
    span {
      font-size: 1.3rem;
    }
    .productDetails-SizeBtnContainer {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
      gap: 16px;
      padding: 10px 0;
      .productDetails-SizeBtnSelected {
        background-color: var(--navBarBG) !important;
        color: white;
      }
      .productDetails-SizeBtn {
        min-width: 50px;
        height: 30px;
        border: 2px solid var(--navBarBG);
        background-color: transparent;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .productDetails-StylesContainer {
    width: 100%;
    span {
      font-size: 1.3rem;
    }
    .productDetails-StyleBtnContainer {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 16px;
      padding: 10px 0;
      .productDetails-StyleBtnSelected {
        background-color: var(--navBarBG) !important;
        color: white;
      }
      .productDetails-StyleBtn {
        min-width: 50px;
        height: 30px;
        border: 2px solid var(--navBarBG);
        background-color: transparent;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  .selectQuantity-Container {
    span {
      font-size: 1.3rem;
    }
    .selectQuantity-Box {
      display: flex;
      width: 150px;
      height: 40px;
      margin-top: 10px;
      .selectQuantity-AddBtn {
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: white;
        background-color: var(--navBarBG);
        cursor: pointer;
        user-select: none;
      }
      .selectQuantity-SubtractBtn {
        cursor: pointer;
        user-select: none;
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: white;
        background-color: var(--navBarBG);
      }
      .selectQuantity-text {
        cursor: default;
        user-select: none;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--navBarBG);
        font-size: 1.3rem;
      }
    }
  }
  .productDetails-btnsContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    .productDetails-addToCart {
      width: 200px;
      height: 50px;
      font-size: 1.2rem;
      background-color: transparent;
      border: 2px solid var(--navBarBG);
      color: var(--navBarBG);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: var(--navBarBG);
        border: 2px solid var(--navBarBG);
        color: white;
      }
    }
    .productDetails-buyNow {
      margin-top: 20px;
      width: 200px;
      height: 50px;
      font-size: 1.2rem;
      background-color: transparent;
      border: 2px solid var(--navBarBG);
      color: var(--navBarBG);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: var(--navBarBG);
        border: 2px solid var(--navBarBG);
        color: white;
      }
    }
  }
}
