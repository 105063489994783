#checkout-cancelBtn {
  &:focus {
    outline: none !important;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}
#checkout-orderBtn {
  &:focus {
    outline: none !important;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}
